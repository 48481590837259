<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center" class="mb-2">
        <v-col cols="auto" class="pr-12">
          <h1>Calendar</h1>
        </v-col>
      </v-row>
      <div :style="{ position: 'relative' }">
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              depressed
              class="me-4"
              color="grey lighten-2"
              @click="setToday"
            >
              View Today
            </v-btn>
            <v-btn
              fab
              depressed
              variant="text"
              small
              color="grey lighten-2"
              class="mr-2"
              @click="prev"
            >
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn
              fab
              depressed
              variant="text"
              small
              color="grey lighten-2"
              class="mr-2"
              @click="next"
            >
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu location="bottom end">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  color="grey lighten-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon end> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-overlay :value="loading" absolute z-index="2">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey-lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              </v-toolbar>
              <v-card-actions>
                <v-btn
                  variant="text"
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  Close
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="accent"
                  :to="{
                    name: 'module-arnprior-orders-individual',
                    params: { orderId: selectedEvent.id },
                  }"
                  >View Booking</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </div>
    </v-container>
  </div>
</template>

<script>
import { arnprior } from "@/modules/arnprior/mixins";
import _ from "lodash";

export default {
  mixins: [arnprior],

  data() {
    return {
      breadcrumbs: [
        {
          text: "Glamping Pods",
          disabled: true,
        },
        {
          text: "Calendar",
          disabled: true,
        },
      ],
      loading: false,
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
    };
  },

  mounted() {
    this.$refs.calendar.checkChange();
  },

  computed: {
    bookings() {
      return this.$store.getters["arnprior/bookingsStore/all"];
    },
  },

  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      this.loading = true;
      this.events = [];

      this.$store
        .dispatch("arnprior/bookingsStore/loadCalendarBookings", {
          type: "glamping",
          appId: this.$route.params.id,
          fields: {
            start: start.date,
            end: end.date,
          },
        })
        .then(() => {
          let bookings = _.cloneDeep(this.bookings);

          bookings = bookings.filter((booking) => {
            const startDate = booking.start_date.substr(0, 10);
            const endDate = booking.end_date.substr(0, 10);

            if (
              booking.booking_is.booking_type === "pod" &&
              this.type == "day"
            ) {
              return (
                (startDate >= start.date && startDate <= end.date) ||
                (endDate >= start.date && endDate <= end.date) ||
                (startDate < start.date && endDate > end.date)
              );
            } else {
              return (
                (startDate >= start.date && startDate <= end.date) ||
                (endDate >= start.date && endDate <= end.date)
              );
            }
          });

          let events = bookings.map((booking) => {
            const bookingStart = new Date(booking.start_date);
            const bookingEnd = new Date(booking.end_date);
            let color, name;

            if (booking.booking_is.booking_type === "swim") {
              color = "#C5E1A5";
              name = booking.order.customer.full_name + ", Booked Swim";
            } else if (booking.booking_is.booking_type === "pod") {
              if (booking.booking_is.pod.name === "Brae Face") {
                color = "yellow";
                name = booking.order.customer.full_name + ", Brae Face";
              } else if (booking.booking_is.pod.name === "Roundel") {
                color = "#00B8D4";
                name = booking.order.customer.full_name + ", Roundel";
              } else if (booking.booking_is.pod.name === "Kirsty's Knowe") {
                color = "#FF8F00";
                name = booking.order.customer.full_name + ", Kirsty's Knowe";
              } else {
                color = "#EF5350";
                name = booking.order.customer.full_name + ", Four Acre";
              }
            } else if (booking.booking_is.booking_type === "party-pod") {
              color = "#42A5F5";
              name = booking.order.customer.full_name + ", Party Pod";
            } else {
              color = "#C5E1A5";
              name = booking.order.customer.full_name + ", Pool Party";
            }

            return {
              id: booking.order.id,
              name,
              start: bookingStart,
              end: bookingEnd,
              color,
              timed: true,
            };
          });

          this.events = events;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
